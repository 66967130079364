table.table-transfez {
  thead {
    tr {
      background: white;
      height: 56px;
      color: inherit;
      &:hover {
        text-decoration: none;
        color: inherit;
        td.action {
          div {
            visibility: visible;
          }
          button {
            visibility: visible;
          }
        }
      }
      td {
        color: #727272;
        font-family: "GothamMedium";
        font-size: 14px;
        vertical-align: middle;
        margin: 0 5px;
        border-top: none;
        &.empty {
          text-align: center;
          vertical-align: middle;
          padding: 20px 0px;
        }
        &.action {
          div {
            visibility: hidden;
          }
          button {
            visibility: hidden;
          }
        }
      }
    }
  }
  tbody {
    tr {
      background: white;
      cursor: pointer;
      height: 48px;
      color: inherit;
      &:hover {
        background: #f5f5f5;
        text-decoration: none;
        color: inherit;
        td.action {
          div {
            visibility: visible;
          }
          button {
            visibility: visible;
          }
        }
      }
      td {
        font-family: "GothamBook";
        font-size: 14px;
        vertical-align: middle;
        margin: 0 5px;
        &.empty {
          text-align: center;
          vertical-align: middle;
          padding: 20px 0px;
        }
        &.action {
          div {
            visibility: hidden;
          }
          button {
            visibility: hidden;
          }
        }
      }
    }
  }
}

table.table-seamless {
  thead {
    tr {
      background: white;
      height: 56px;
      color: inherit;
      &:hover {
        text-decoration: none;
        color: inherit;
        td.action {
          div {
            visibility: visible;
          }
          button {
            visibility: visible;
          }
        }
      }
      th {
        position: sticky;
        top: 0;
        z-index: 2;
        background-color: white;
        border-bottom: 1px solid #eaeaea;
        border-top: 1px solid white;
        color: #727272;
        font-family: "GothamMedium";
        font-size: 14px;
        vertical-align: middle;
        margin: 0 5px;
        box-shadow: 0 -1px #eaeaea inset;
        &.empty {
          text-align: center;
          vertical-align: middle;
          padding: 20px 0px;
          background-color: white;
        }
        &.action {
          div {
            visibility: hidden;
          }
          button {
            visibility: hidden;
          }
        }
      }
    }
  }
  tbody {
    tr {
      background: white;
      cursor: pointer;
      height: 48px;
      color: inherit;
      &:hover {
        background: #f5f5f5;
        text-decoration: none;
        color: inherit;
        td.action {
          div {
            visibility: visible;
          }
          button {
            visibility: visible;
          }
        }
      }
      td {
        border-bottom: 1px solid transparent;
        vertical-align: middle;
        margin: 0 5px;
        //we can use this animation for smooth data presentation but when we open the three dots,
        //the three dots below it somehow get in front of the dropdown option
        // opacity: 0;
        // animation: fadeIn 600ms ease-in 250ms 1 normal forwards;
        &.empty {
          text-align: center;
          vertical-align: middle;
          padding: 20px 0px;
        }
        &.action {
          div {
            visibility: hidden;
          }
          button {
            visibility: hidden;
          }
        }
      }
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}

table.table-seamless-advanced {
  border-collapse: separate;
  thead {
    tr {
      background: white;
      height: 56px;
      color: inherit;
      &:hover {
        text-decoration: none;
        color: inherit;
        td.action {
          div {
            visibility: visible;
          }
          button {
            visibility: visible;
          }
        }
      }
      th {
        position: sticky;
        top: 0;
        z-index: 2;
        background-color: white;
        border-bottom: 1px solid #eaeaea;
        border-top: 1px solid white;
        color: #727272;
        font-family: "GothamMedium";
        font-size: 14px;
        vertical-align: middle;
        margin: 0 5px;
        box-shadow: 0 -1px #eaeaea inset;
        &.empty {
          text-align: center;
          vertical-align: middle;
          padding: 20px 0px;
          background-color: white;
        }
        &.action {
          div {
            visibility: hidden;
          }
          button {
            visibility: hidden;
          }
        }
      }
    }
  }
  tbody {
    tr {
      transition: all 0.4s linear;
      background: white;
      cursor: pointer;
      height: 48px;
      color: inherit;
      td {
        border-bottom: 1px solid transparent;
        vertical-align: middle;
        margin: 0 5px;
        &.empty {
          text-align: center;
          vertical-align: middle;
          padding: 20px 0px;
        }
        &.action {
          div {
            visibility: hidden;
          }
          button {
            visibility: hidden;
          }
        }
      }
      &:hover {
        box-shadow: 0px 4px 8px rgba(88, 88, 88, 0.06);
        td {
          border-bottom: 1px solid rgba(230, 230, 232, 1);
          border-top: 1px solid rgba(230, 230, 232, 1);
          &:first-child {
            border-left: 1px solid rgba(230, 230, 232, 1);
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }
          &:last-child {
            border-right: 1px solid rgba(230, 230, 232, 1);
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
          }
        }
        background: #f5f5f5;
        text-decoration: none;
        color: inherit;
        td.action {
          div {
            visibility: visible;
          }
          button {
            visibility: visible;
          }
        }
      }
    }
  }
}

table.table-seamless-pink {
  thead {
    tr {
      background: white;
      height: 56px;
      color: inherit;
      &:hover {
        text-decoration: none;
        color: inherit;
        td.action {
          div {
            visibility: visible;
          }
          button {
            visibility: visible;
          }
        }
      }
      th {
        position: sticky;
        top: 0;
        z-index: 2;
        background-color: white;
        border-bottom: 1px solid #eaeaea;
        border-top: 1px solid white;
        color: #727272;
        font-family: "GothamMedium";
        font-size: 14px;
        vertical-align: middle;
        margin: 0 5px;
        box-shadow: 0 -1px #eaeaea inset;
        &.empty {
          text-align: center;
          vertical-align: middle;
          padding: 20px 0px;
          background-color: white;
        }
        &.action {
          div {
            visibility: hidden;
          }
          button {
            visibility: hidden;
          }
        }
      }
    }
  }
  tbody {
    tr {
      background: white;
      cursor: pointer;
      height: 48px;
      color: inherit;
      &:hover {
        background: #fdd7f5;
        text-decoration: none;
        color: inherit;
        td.action {
          div {
            visibility: visible;
          }
          button {
            visibility: visible;
          }
        }
      }
      td {
        border-bottom: 1px solid transparent;
        vertical-align: middle;
        margin: 0 5px;
        //we can use this animation for smooth data presentation but when we open the three dots,
        //the three dots below it somehow get in front of the dropdown option
        // opacity: 0;
        // animation: fadeIn 600ms ease-in 250ms 1 normal forwards;
        &.empty {
          text-align: center;
          vertical-align: middle;
          padding: 20px 0px;
        }
        &.action {
          div {
            visibility: hidden;
          }
          button {
            visibility: hidden;
          }
        }
      }
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}

table.table-seamless-local {
  thead {
    tr {
      // background-color: red;
      height: 52px;
      color: inherit;
      &:hover {
        text-decoration: none;
        color: inherit;
        td.action {
          div {
            visibility: visible;
          }
          button {
            visibility: visible;
          }
        }
      }
      th {
        position: sticky;
        top: -1px;
        z-index: 2;
        background-color: #e6e6e8;
        border-bottom: 1px solid transparent;
        border-top: 1px solid white;
        color: #333334;
        font-family: "GothamMedium";
        font-size: 14px;
        vertical-align: middle;
        margin: 0 5px;
        // box-shadow: 0 -1px #eaeaea inset;
        &.empty {
          text-align: center;
          vertical-align: middle;
          padding: 20px 0px;
          background-color: white;
        }
        &.action {
          div {
            visibility: hidden;
          }
          button {
            visibility: hidden;
          }
        }
      }
    }
  }
  tbody {
    tr {
      background: white;
      cursor: pointer;
      height: 48px;
      color: inherit;
      &:hover {
        background: #f5f5f5;
        text-decoration: none;
        color: inherit;
        td.action {
          div {
            visibility: visible;
          }
          button {
            visibility: visible;
          }
        }
      }
      td {
        border-bottom: 1px solid transparent;
        vertical-align: middle;
        margin: 0 5px;
        //we can use this animation for smooth data presentation but when we open the three dots,
        //the three dots below it somehow get in front of the dropdown option
        // opacity: 0;
        // animation: fadeIn 600ms ease-in 250ms 1 normal forwards;
        &.empty {
          text-align: center;
          vertical-align: middle;
          padding: 20px 0px;
        }
        &.action {
          div {
            visibility: hidden;
          }
          button {
            visibility: hidden;
          }
        }
      }
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}
