@keyframes rotate-close {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.close-accordion {
  animation: rotate-close forwards 0.5s;
}

@keyframes rotate-open {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

.open-accordion {
  transform: rotate(180deg);
}

@keyframes fade-in-keyframes {
  from {
    display: none;
    opacity: 0;
  }
  to {
    display: flex;
    opacity: 1;
  }
}

.fade-in {
  animation: fade-in-keyframes forwards 1s;
}

@keyframes fade-out-keyframes {
  from {
    display: flex;
    opacity: 1;
  }
  to {
    display: none;
    opacity: 0;
  }
}

.fade-out {
  animation: fade-out-keyframes forwards 0.5s;
}

@keyframes slide-out-keyframes {
  0% {
    transform: translateX(250px);
  }
  100% {
    transform: translateX(0);
  }
}

.slide-out {
  animation-name: slide-out-keyframes;
  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

@keyframes slide-in-keyframes {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(250px);
  }
}

.slide-in {
  animation-name: slide-in-keyframes;
  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

@keyframes slide-out-keyframes {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

.slide-down {
  animation-name: slide-out-keyframes;
  animation-duration: 0.25s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes slide-in-keyframes {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.slide-up {
  animation-name: slide-in-keyframes;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

.rotate-icon {
  -moz-transition: all 0.25s linear;
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
}

.rotate-icon.down {
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
