.error-toaster {
  background-color: #e95757;
  border-radius: 12px;
  box-shadow: 2px 3px 39px -4px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 2px 3px 39px -4px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 2px 3px 39px -4px rgba(0, 0, 0, 0.4);
}
.success-toaster {
  background-color: #28ab7c;
  border-radius: 12px;
  box-shadow: 2px 3px 39px -4px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 2px 3px 39px -4px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 2px 3px 39px -4px rgba(0, 0, 0, 0.4);
}

.warning-toaster {
  background-color: #ed991c;
  border-radius: 4px;
  min-height: 48px;
  height: auto;
  box-shadow: 0px 4px 12px -4px rgba(22, 34, 51, 0.12),
    0px 16px 32px rgba(22, 34, 51, 0.16);
  -webkit-box-shadow: 2px 3px 39px -4px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 2px 3px 39px -4px rgba(0, 0, 0, 0.4);
}

.error-snackbar {
  background-color: #feacaa;
  border-radius: 4px;
  min-height: 48px;
  height: auto;
  box-shadow: 0px 4px 12px -4px rgba(22, 34, 51, 0.12),
    0px 16px 32px rgba(22, 34, 51, 0.16);
  -webkit-box-shadow: 2px 3px 39px -4px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 2px 3px 39px -4px rgba(0, 0, 0, 0.4);
}

.success-snackbar {
  background-color: #9bdeb8;
  /* height: 200px; */
  min-height: 48px;
  height: auto;
  border-radius: 4px;
  box-shadow: 0px 4px 12px -4px rgba(22, 34, 51, 0.12),
    0px 16px 32px rgba(22, 34, 51, 0.16);
  -webkit-box-shadow: 2px 3px 39px -4px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 2px 3px 39px -4px rgba(0, 0, 0, 0.4);
}
