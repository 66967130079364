.summary-sheet-responsive {
  background-color: #343434;
  height: 80px;
  width:100vw;
  position: fixed;
  left:0px;
  bottom: 0px;
  padding: 16px 24px;
  z-index: 99 !important;
  box-shadow: 0px 4px 12px -4px rgba(22, 34, 51, 0.12), 0px 16px 32px rgba(22, 34, 51, 0.16);
}

@media (min-width: 1280px) {
  .summary-sheet-responsive {
    width:900px;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 8px;
  }
}

.summary-sheet-small {
  z-index: 99 !important;
  background-color: #343434;
  height: 80px;
  width:900px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px;
  bottom: 20px;
  padding: 12px 24px;
  box-shadow: 0px 4px 12px -4px rgba(22, 34, 51, 0.12), 0px 16px 32px rgba(22, 34, 51, 0.16);
}

.loading-bank {
  animation: fidgetspiner;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}

@keyframes fidgetspiner {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}

@keyframes grow {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.stepper-active {
  height: 100%;
  width: 0%;
  background-color: #343434;
  animation-name: grow;
  animation-duration: 1700ms;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

@keyframes switchopacity {
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}
.activedot {
  animation-name: switchopacity;
  animation-duration: 2500ms;
  animation-timing-function: ease-in;
}

.animate-in {
  opacity: 0;
  transform: translateY(-20px);
  animation: animate-in 100s ease forwards;
}

@keyframes animate-in {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.show-animation {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.show-animation.show {
  opacity: 1;
}

.zoom-container {
  width:137px;
  height:44px;
  padding: 14px 16px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  background-color: #343434;
}


.tdrow-invoice {
  border-top: 1px solid #E6E6E8;
  background-color: #ffffff;
}

.tdrow-invoice:hover:not(:has(.threedot:hover)) {
  background-color:#F1F1F1;
  cursor: pointer;
}

.threedot {

}

.threedot:hover {
  cursor:pointer;
}

.preventblueblock {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.bankcard {
  background-color: #ffffff;
}

.bankcard:hover {
  background-color:#F1F1F1;
}

.refreshButton {
  transform: rotate(0deg);
}

.refreshButton:active {
  transform: rotate(270deg);
  transition: transform 0.1s ease-out;
}

.smoothScale {
  animation: animate-in ;  
}

.iconHover{

}

.iconHover:hover {
  transform: scale(1.15);
}

@keyframes smoothRender {
  from {
    height: 0%;
    opacity: 0;
  }

  to {
    height: 100%;
    opacity: 1;
  }
}

.smoothIn {
  animation: smoothRender 70ms ease-in-out;
}
.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}