html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  -webkit-appearance: none !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* @font-face {
  font-family: "GothamLight";
  src: local("GothamLight"),
    url(../assets/fonts/Gotham-Light.ttf) format("truetype");
}
@font-face {
  font-family: "GothamBook";
  src: local("GothamBook"),
    url(../assets/fonts/Gotham-Book.ttf) format("truetype");
}
@font-face {
  font-family: "GothamBold";
  src: local("GothamBold"),
    url(../assets/fonts/GOTHAM-BOLD.ttf) format("truetype");
}
@font-face {
  font-family: "GothamMedium";
  src: local("GothamMedium"),
    url(../assets/fonts/Gotham-Medium.ttf) format("truetype");
} */
@font-face {
  font-family: "GothamLight";
  src: local("GothamLight"),
    url(../assets/fonts/TypeFezStd-Light.ttf) format("truetype");
}
@font-face {
  font-family: "GothamBook";
  src: local("GothamBook"),
    url(../assets/fonts/TypeFezStd-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "GothamBold";
  src: local("GothamBold"),
    url(../assets/fonts/TypeFezStd-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "GothamMedium";
  src: local("GothamMedium"),
    url(../assets/fonts/TypeFezStd-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "HalenoirExpandedBlack";
  src: local("HalenoirExpandedBlack"),
    url(../assets/fonts/HalenoirExpanded-Black.otf) format("opentype");
}
@font-face {
  font-family: "TypeFezNeueBold";
  src: local("TypeFezNeueBold"),
    url(../assets/fonts/TypeFezNeue-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "TypeFezNeueRegular";
  src: local("TypeFezNeueRegular"),
    url(../assets/fonts/TypeFezNeue-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "TypeFezNeueMedium";
  src: local("TypeFezNeueMedium"),
    url(../assets/fonts/TypeFezNeue-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "TypeFezNeueLight";
  src: local("TypeFezNeueLight"),
    url(../assets/fonts/TypeFezNeue-Light.ttf) format("truetype");
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 9999s ease-in-out 0s;
}

:root {
  touch-action: pan-x pan-y;
  height: 100%;

  --black34: #343434;
  --greyBB: #bbbbc0;
  --redE7: #e73126;
  --greenB9: #b9fc00;
  --grey6C: #6c6c71;
  --grey90: #909098;
  --greyF1: #f1f1f1;
  --greyE6: #e6e6e8;
  --green06: #06ac4e;
  --neutral100: #ffffff;
  --neutral200: #fcfcfc;
  --neutral400: #f1f1f1;
  --neutral500: #e6e6e8;
  --neutral600: #bbbbc0;
  --neutral700: #909098;
  --neutral800: #6c6c71;
  --neutral900: #343434;
  --greyFC: #fcfcfc;

  /* Team colors */
  --maroon47: #47001e;
  --orangeF8: #f8762d;
  --yellowFF: #ffcb00;
  --cyan04: #04c7b3;
  --blue1C: #1c4c6c;
  --green23: #238730;
  --magenta87: #876fde;
  --pinkDE: #de738b;
  --brownC6: #c69b7b;
  --redDC: #dc2f44;
  --blue60: #6087e7;
  --purple5A: #5a489c;
  --purple7F: #7f0b5c;
  --teracottaAA: #aa3511;
  --orangeF2: #f26727;
  --yellowF4: #f4d53d;
  --orangeFF: #ff7b5a;
  --green2C: #2cc4b5;
  --blue54: #54c4d8;
  --blue0A: #0a7c8c;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.pincode-input-container {
  margin: 0px;
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.pincode-input-container input {
  background: #f1f1f1 !important;
  width: 40px !important;
  height: 56px !important;
  color: #343434 !important;
  border: none !important;
  border-bottom: 1px solid #bbbbc0 !important;
  margin: 0px !important;
  font-size: 32px;
  font-weight: 500;
}

.public-DraftStyleDefault-ol,
.public-DraftStyleDefault-ul {
  margin: 0 !important;
}

.react-datepicker__header__dropdown {
  margin-bottom: 8px;
}

.react-datepicker__year-read-view--down-arrow {
  margin-top: 6px;
}

.react-datepicker__month-read-view--down-arrow {
  margin-top: 6px;
}

.react-datepicker__month-read-view--selected-month {
  font-size: 14px;
  font-family: GothamMedium;
  color: #343434;
}

.react-datepicker__year-read-view--selected-year {
  font-size: 14px;
  font-family: GothamMedium;
  color: #343434;
}

.react-datepicker__month-read-view {
  visibility: visible !important;
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
}

.react-datepicker__year-read-view {
  visibility: visible !important;
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
}

.clearfix:before,
.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
}
.clearfix:after {
  clear: both;
}
.clearfix {
  zoom: 1;
} /* IE < 8 */

.modal-content {
  border: none;
}
