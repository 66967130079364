.badge {
  background-color: #ffd271;
  border-radius: 4px;
  color: white;
  font-size: 14px;
  height: 40px;
  font-family: GothamMedium;
}

.badge:hover {
  cursor: default;
}
