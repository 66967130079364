.searchBatch {
  width: 476px;
  padding: 8px 8px 8px 36px;
  font-size: 14px;
  font-family: GothamBook;
  border-radius: 8px;
  border: 1px solid #e6e6e8;
  color: #727272;
}

.searchBatch:hover {
  border: 1px solid #bbbbc0;
}

.searchBatch:active,
.searchBatch:focus {
  outline: none;
}

.hideScrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

.hideScrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/*hide increase decrease type number*/
/*Chrome, Safari, Edge, Opera*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

.textfield {
  border: 1px solid #e6e6e8 !important;
  -webkit-appearance: none !important;
  appearance: none !important;

  /* Vendor-prefixed versions for older browsers */
  -webkit-appearance: none !important; /* Chrome, Safari */
  -moz-appearance: none;
}

.textfield::placeholder {
  color: var(--neutral600);
}

.textfield:hover {
  border: 1px solid #bbbbc0 !important;
}

.bottomBorderTextfield {
  border-bottom: 1px solid #e6e6e8 !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  background-color: transparent !important;

  /* Vendor-prefixed versions for older browsers */
  -webkit-appearance: none !important; /* Chrome, Safari */
  -moz-appearance: none;
}

.bottomBorderTextfield:hover {
  border-bottom: 1px solid #bbbbc0 !important;
}

.bottomBorderTextfield::placeholder {
  color: var(--neutral600);
}

.bottomBorderTextfield:focus {
  border-bottom: 1px solid black !important;
}

.bottomBorderTextfield-focus {
  border-bottom: 1px solid black !important;
}

.react-datepicker__header {
  background-color: white;
  border: 0px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #343434;
  display: inline-block;
  width: 2.15rem;
  height: 2rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.066rem;
  margin-left: 0.016rem;
  margin-right: 0.016rem;
  font-size: 14px;
  padding-top: 2px;
}
.react-datepicker__day--disabled {
  color: #909098;
}
.react-datepicker__day--selected {
  color: #b9fc00;
  background-color: #343434;
}

.react-datepicker__day-name {
  color: #909098;
}

.react-datepicker__day--keyboard-selected {
  color: #b9fc00;
  background-color: #343434;
}
.react-datepicker__day:hover {
  color: white;
  background-color: #909098;
}

.react-datepicker__day--in-range {
  background-color: #909098;
}

/* .react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__month-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__quarter-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__year-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ) {
  background-color: red;
} */

::-webkit-resizer {
  display: none;
}

.inputCustom::placeholder {
  color: #bbbbc0;
}
.inputCustom:focus {
  transition: 0.3s;
  border: 1px solid #343434 !important;
}
