.button__bar {
  display: inline-block;
  vertical-align: middle;
  margin: auto;
  padding: 0;
  /* background: rgba(255, 255, 255, 0.3); */
  border-radius: 40px;
  width: 120px;
  text-align: center;
}

.button__bar li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
  padding: 0;
}

.button__bar li button {
  border: none;
  background: #fdd7f5;
  color: transparent;
  cursor: pointer;
  display: block;
  height: 12px;
  width: 12px;
  border-radius: 7.5px;
}

.button__bar li.slick-active button {
  background-color: rgb(8, 255, 140);
  width: 40px;
  height: 12px;
  border-radius: 8px;
  background-color: #da649f;
}

.button__bar li button:hover,
.button__bar li button:focus {
  /* background: rgba(247, 34, 34, 0.8); */
  outline: 0;
}

.slick-slide img {
  display: block;
  margin: auto;
}
